<template>
  <form class="form w-100 needs-validation">
    <div class="text-center mb-10">
      <h1 class="mb-3">
        <span>
          {{ $config.localization[activeLanguage].auth.title }}
        </span>
      </h1>
    </div>
    <div
      class="alert alert-warning fs-4 bg-light-primary rounded border-primary border border-dashed"
      v-if="String.isNullOrWhiteSpace(routeQuery.authToken)"
    >
      {{ $t("Authenticate.RedirectMessage", {}, { locale: activeLanguage }) }}
    </div>
    <div
      class="alert alert-success fs-4 bg-light-primary rounded border-primary border border-dashed"
      v-else
    >
      {{
        $t("Authenticate.LoginOperationMessage", {}, { locale: activeLanguage })
      }}
    </div>
  </form>
</template>
<script>
import {
  removeModalBackdrops,
  removeModals,
  removeModalDependecyClassesAndStyles,
} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { locale } from "devextreme/localization";

export default {
  name: "Authenticate",
  props: ["authToken"],
  data() {
    return {
      activeLanguage:
        localStorage.getItem("activeLang") || this.$store.state.activeLang,
      routeQuery: this.$route.query,
    };
  },
  methods: {
    changeDocumentTitle() {
      this.$root.changeDocumentTitle(
        this.$t("BaseModelFields.Login", {}, { locale: this.activeLanguage })
      );
    },
  },
  mounted() {
    this.changeDocumentTitle();
    this.$root.companyImportedDependenciesRemove();

    removeModalBackdrops();
    removeModals();
    removeModalDependecyClassesAndStyles();
  },
  created() {
    if (!Boolean.toBool(process.env.VUE_APP_LDAP_AUTHENTICATION_IS_ACTIVE)) {
      this.$router.push({ name: "sign-in" });
      return;
    }

    this.changeDocumentTitle();

    var authToken = this.routeQuery.authToken,
      isOk = this.routeQuery.isOk;
    if (String.isNullOrWhiteSpace(authToken)) {
      if (!String.isNullOrWhiteSpace(isOk) && !Boolean.toBool(isOk)) {
        this.$router.push({
          path: "/sign-in",
          query: { passwordLogin: true },
        });
      } else {
        setTimeout(() => {
          window.location.href = `${
            process.env.VUE_APP_LDAP_AUTHENTICATION_WEB_API_URL
          }/${encodeURIComponent(window.location.href)}`;
        }, 3000);
      }
    } else {
      this.$authAxios
        .post("/Authenticate", {
          authToken: encodeURIComponent(authToken),
          authenticationTypeId: process.env.VUE_APP_LDAP_AUTHENTICATION_TYPE_ID,
        })
        .then((login_response) => {
          var response = login_response.data;
          if (response.isOk) {
            this.$store.commit("setUser", response);
            this.$root.setI18nLocaleValue(response.activeLanguage);
            locale(response.activeLanguage);

            this.$store.dispatch("addBodyClassName", "page-loading");

            this.$root.applyTheme();
            //get current user and redirecting
            this.$root.getCurrentUser(true);
          } else {
            if (!String.isNullOrWhiteSpace(response.passwordResetToken)) {
              this.$router.push({
                name: "password-change",
                query: {
                  token: response.passwordResetToken,
                },
              });

              Swal.fire({
                html: `<span class='fw-bold fs-5'>${this.$t(
                  "PasswordChange.PasswordResetNeeded",
                  {},
                  { locale: this.activeLanguage }
                )}</span>`,
                icon: "error",
                showDenyButton: false,
                buttonsStyling: false,
                confirmButtonText: this.$t(
                  "BaseModelFields.Close",
                  {},
                  { locale: this.activeLanguage }
                ),
                customClass: {
                  confirmButton: "btn fw-bold btn-success",
                },
              });

              return;
            }

            this.$router.push({
              path: "/sign-in",
              query: { passwordLogin: true },
            });

            var errorMessage = response.message,
              errorMessageIsNull = String.isNullOrWhiteSpace(errorMessage),
              passwordResetButtonShow =
                response.message ===
                process.env.VUE_APP_MEMBERSHIP_INVALID_EMAIL_OR_PASSWORD_KEY;
            if (errorMessageIsNull) {
              errorMessage = this.$t(
                "BaseModelFields.UnexpectedErrorHasOccurred",
                {},
                { locale: this.activeLanguage }
              );
            } else {
              errorMessage = this.$root.getMessageByKey(errorMessage);
            }

            Swal.fire({
              html: `<span class='fw-bold fs-5'>${errorMessage}</span>`,
              icon: "error",
              showDenyButton: passwordResetButtonShow,
              denyButtonText:
                "<i class='fa fa-lock fs-5'></i> " +
                this.$t(
                  "BaseModelFields.ForgotYourPassword",
                  {},
                  { locale: this.activeLanguage }
                ),
              buttonsStyling: false,
              confirmButtonText: this.$t(
                "BaseModelFields.TryAgain",
                {},
                { locale: this.activeLanguage }
              ),
              customClass: {
                confirmButton: "btn fw-bold btn-success",
                denyButton: "btn fw-bold btn-info me-2",
              },
              reverseButtons: true,
            }).then((result) => {
              if (result.isDenied) {
                self.$router.push({ name: "password-reset" });
              }
            });
          }
        })
        .catch((response) => {
          this.$router.push({
            path: "/sign-in",
            query: { passwordLogin: true },
          });

          var errorMessage = this.$t(
            "BaseModelFields.UnexpectedErrorHasOccurred",
            {},
            { locale: this.activeLanguage }
          );
          Swal.fire({
            html: `<span class='fw-bold fs-5'>${errorMessage}</span>`,
            icon: "error",
            showDenyButton: true,
            denyButtonText:
              "<i class='fa fa-users fs-5'></i> " +
              this.$t(
                "BaseModelFields.SupportTeam",
                {},
                { locale: this.activeLanguage }
              ),
            buttonsStyling: false,
            confirmButtonText: this.$t(
              "BaseModelFields.TryAgain",
              {},
              { locale: this.activeLanguage }
            ),
            customClass: {
              confirmButton: "btn fw-bold btn-success",
              denyButton: "btn fw-bold btn-info me-2",
            },
            reverseButtons: true,
          }).then((result) => {
            if (result.isDenied) {
              window.open(this.$config.webSites.support);
            }
          });
        });
    }
  },
};
</script>
